import React from 'react';
import { Router, Switch, Route } from 'dva/router';
import dynamic from 'dva/dynamic'

function RouterConfig({ history, app }) {

  const routes = [
    {
      path: '/',
      models: () => [import('./routes/model'), import('./models/common')],
      component: () => import('./routes/index'),
    },
    {
      path: '/apply',
      models: () => [import('./routes/apply/model'), import('./models/common')],
      component: () => import('./routes/apply/index'),
    },
    {
      path: '/applysub',
      models: () => [import('./routes/applysub/model'), import('./models/common')],
      component: () => import('./routes/applysub/index'),
    },
    {
      path: '/record',
      models: () => [import('./routes/record/model'), import('./models/common')],
      component: () => import('./routes/record/index'),
    },
    {
      path: '/detailssub',
      models: () => [import('./routes/detailssub/model'), import('./models/common')],
      component: () => import('./routes/detailssub/index'),
    },
    {
      path: '/accept/:id',
      models: () => [import('./routes/accept/model'), import('./models/common')],
      component: () => import('./routes/accept/index'),
    },
    {
      path: '/deal/:id',
      models: () => [import('./routes/deal/model'), import('./models/common')],
      component: () => import('./routes/deal/index'),
    },
    {
      path: '/confirm/:id',
      models: () => [import('./routes/confirm/model'), import('./models/common')],
      component: () => import('./routes/confirm/index'),
    },
    {
      path: '/details/:id',
      models: () => [import('./routes/details/model'), import('./models/common')],
      component: () => import('./routes/details/index'),
    },
    {
      path: '/exportPlan/:orgId',
      models: () => [import('./routes/export/model'), import('./models/common')],
      component: () => import('./routes/export/index'),
    }
    
  ]

  return (
     <Router history={history}>
        <Switch>
          {
            routes.map(({ path, ...dynamics }, key) => (
              <Route key={key}
                exact
                path={path}
                component={dynamic({
                  app,
                  ...dynamics,
                })}
              />
            ))
          }
        </Switch>
      </Router>
    )
}

export default RouterConfig;
